<template>
  <div class="materials-slider">
    <div class="materials-slider__head">
      <div class="materials-slider__title" v-html="title"></div>
      <div class="materials-slider__arrows mt-0 ml-auto" v-if="showArrows">
        <div
          class="materials-slider__arrow mr-3"
          @click="$refs[`materialsSlider_${_uid}`].prev()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.50012L3.75 12.0001L11.25 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12.0001L20.25 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div
          class="materials-slider__arrow ml-3"
          @click="$refs[`materialsSlider_${_uid}`].next()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12.0001L3.75 12.0001"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>

    <VueSlickCarousel
      v-bind="settings"
      :ref="`materialsSlider_${_uid}`"
      @init="onInitCarousel"
      @reInit="onInitCarousel"
    >
      <slot name="items"></slot>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "MaterialsSlider",
  components: {
    VueSlickCarousel,
  },
  props: {
    title: String,
    count: {
      type: [String, Number],
      default: 0,
    },
  },
  data: () => ({
    showArrows: true,
    settings: {
      infinite: false,
      slidesToShow: 3,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  }),
  computed: {},
  methods: {
    onInitCarousel() {
      this.showArrows =
        this.$refs[`materialsSlider_${this._uid}`]?.settings.slidesToShow <
        this.count;
    },
  },
};
</script>

<style scoped lang="scss">
.materials-slider {
  width: 100%;
  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__arrows {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d8dada;
    cursor: pointer;
    color: #8b8e8e;
    transition: 0.3s;

    @media screen and (max-width: 767px) {
      width: 24px;
      height: 24px;

      & svg {
        width: 60%;
        height: 60%;
      }
    }

    &:hover {
      color: #830051;
      background-color: #ebefee;
    }
  }
}
</style>

<style lang="scss">
.materials-slider {
  max-width: 904px;
  @media screen and (max-width: 1220px) {
  }
  .slick-slider {
    margin: 0 -16px;
  }

  .slick-list,
  .slick-track {
    display: flex;
  }

  .slick-slide {
    padding: 0 16px;
    height: auto;
  }

  .slick-slide > div {
    height: 100%;
  }

  .material {
    height: 100%;

    &__image-container {
      height: 150px !important;
    }
  }

  .project-item {
    height: 100%;

    &__image-container {
      height: 150px !important;
    }
  }
}
</style>